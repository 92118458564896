import React, { ChangeEvent, useState } from 'react';

type Values = {
    username: string;
    password: string;
}

export default function Login({ setToken }:{setToken:any}) {
    const [values, setValues] = useState<Values>({
        username: '',
        password: ''
    });
    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const functionKey = process.env.REACT_APP_kotisivut_admin;
            const response = await fetch(`https://pyorafunction.azurewebsites.net/api/login?code=${functionKey}`, {
                method : 'POST',
                mode: 'cors',
                headers: {
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    username: values.username,
                    password: values.password
                })
            });
            if(response.status === 200) {
                let token = await response.json();
                setToken(token);
            }
          } catch (error) {
            console.log("Error: " + error);
            alert("Error: " + error);
          }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValues({...values, [e.target.name]: e.target.value});
    };

    return (
        <div className="Login">
            <h1>Kirjaudu sisään </h1>
            <form className="Login" onSubmit={e => handleSubmit(e)}>
                <input  type="text" 
                        className="Login-input" 
                        placeholder="Käyttäjätunnus"
                        onChange={ handleChange }
                        name = "username" 
                        value = { values.username } />
                <input  type="password"
                        className="Login-input"
                        placeholder="Salasana"
                        onChange={ handleChange }
                        name = "password"
                        value = { values.password } />
                <button type="submit" className="Login-btn"> Kirjaudu </button>
            </form>
        </div>
    );
}