import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import logo from "../images/logo_iso.png";
import { useTranslation, Trans } from 'react-i18next';

type TextData = {
    frontDescription: string;
    frontDescriptionEn: string;
    openingHours: string;
    openingHoursEn: string;
    additionalInfo: string;
    additionalInfoEn: string;
};

export default function Front() {
    const { t, i18n } = useTranslation();
    const [textData, setTextData] = useState<TextData>({
        frontDescription: "",
        frontDescriptionEn: "",
        openingHours: "",
        openingHoursEn: "",
        additionalInfo: "",
        additionalInfoEn: ""
    });

    useEffect(() => {
        const fetchData = async (textName: keyof TextData) => {
            try {
                const response = await fetch(`https://bikes.blob.core.windows.net/texts/${textName}.txt`);
                if (response.ok) {
                    let data = await response.text();
                    setTextData(prev => ({ ...prev, [textName]: data || "" }));
                } else {
                    console.error(`Error fetching data for ${textName}: ${response.statusText}`);
                }
            } catch (error) {
                console.error(`Error fetching data for ${textName}:`, error);
            }
        };

        fetchData("frontDescription");
        fetchData("frontDescriptionEn");
        fetchData("openingHours");
        fetchData("openingHoursEn");
        fetchData("additionalInfo");
        fetchData("additionalInfoEn");
    }, []);

    const getText = (key: keyof TextData) => {
        return i18n.language === 'en' ? textData[`${key}En` as keyof TextData] : textData[key];
    };

    return (
        <div className="Frontpage">
            <Helmet>
                <meta name="description" content="Your description here" />
            </Helmet>
            <div className='logo-container'>
                <img src={logo} alt="" className='frontpage-logo'/>
            </div>
            
            <div className='Frontpage-content'>
                <p>{getText('frontDescription')}</p>
                <p>{getText('openingHours')}</p>
                <p dangerouslySetInnerHTML={{ __html: getText('additionalInfo').replace(/\n/g, '<br/>') }} />
            </div>
        </div>
    );
}