import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminTexts.css'; // Import the CSS file

type TextData = {
    frontDescription: string;
    frontDescriptionEn: string;
    openingHours: string;
    openingHoursEn: string;
    additionalInfo: string;
    additionalInfoEn: string;
};

export default function AdminTexts() {
    const [textData, setTextData] = useState<TextData>({
        frontDescription: "",
        frontDescriptionEn: "",
        openingHours: "",
        openingHoursEn: "",
        additionalInfo: "",
        additionalInfoEn: ""
    });
    const [placeholders, setPlaceholders] = useState<TextData>({
        frontDescription: "Ladataan tietoja...",
        frontDescriptionEn: "Loading data...",
        openingHours: "Ladataan tietoja...",
        openingHoursEn: "Loading data...",
        additionalInfo: "Ladataan tietoja...",
        additionalInfoEn: "Loading data..."
    });
    const [msg, setMsg] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async (textName: keyof TextData) => {
            try {
                const response = await fetch(`https://bikes.blob.core.windows.net/texts/${textName}.txt`);
                if (response.ok) {
                    let data = await response.text();
                    data = data.replace(/<br\s*\/?>/gi, '\n'); // Replace <br> tags with newlines
                    setPlaceholders(prev => ({ ...prev, [textName]: data || "Ei tietoja saatavilla. Kirjoita teksti tähän." }));
                } else {
                    setPlaceholders(prev => ({ ...prev, [textName]: "Ei tietoja saatavilla. Kirjoita teksti tähän." }));
                }
            } catch (error) {
                console.error(`Error fetching data for ${textName}:`, error);
                setPlaceholders(prev => ({ ...prev, [textName]: "Virhe tietojen lataamisessa. Kirjoita teksti tähän." }));
            }
        };

        fetchData("frontDescription");
        fetchData("frontDescriptionEn");
        fetchData("openingHours");
        fetchData("openingHoursEn");
        fetchData("additionalInfo");
        fetchData("additionalInfoEn");
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>, textName: keyof TextData) => {
        setTextData(prev => ({ ...prev, [textName]: e.target.value }));
    };

    const [messages, setMessages] = useState({
        frontDescription: '',
        frontDescriptionEn: '',
        openingHours: '',
        openingHoursEn: '',
        additionalInfo: '',
        additionalInfoEn: ''
    });

    const handleSubmit = async (textName: keyof TextData) => {
        const fileName = `${textName}.txt`;
        const textWithBr = textData[textName].replace(/\n/g, '<br/>');
        const blob = new Blob([textWithBr], { type: 'text/plain' });
        const textFile = new File([blob], fileName);
        const formData = new FormData();
        formData.append(textFile.name, textFile);
        let accessToken = localStorage.getItem('token') || "";
        let accessTokenJSON = JSON.parse(accessToken);
        let token = accessTokenJSON.token || "";
        const response = await fetch(`https://pyorafunction.azurewebsites.net/api/addbike?filename=${fileName}&username=texts`, {
            method: 'POST',
            headers: {
                token: token,
            },
            body: formData
        });
        console.log(response);
        setMessages(prevMessages => ({
            ...prevMessages,
            [textName]: 'Teksti päivitetty!'
        }));
    };

    return (
        <div className="admin-texts-container">
            <h1>Tekstieditori</h1>
            <div className="text-area-container">
                <h2>Etusivun kuvaus</h2>
                <textarea 
                    value={textData.frontDescription || placeholders.frontDescription} 
                    onChange={(e) => handleChange(e, "frontDescription")} 
                    placeholder={placeholders.frontDescription}>
                </textarea>
                <textarea 
                    value={textData.frontDescriptionEn || placeholders.frontDescriptionEn} 
                    onChange={(e) => handleChange(e, "frontDescriptionEn")} 
                    placeholder={placeholders.frontDescriptionEn}>
                </textarea>
                <div className="button-container">
                    <button onClick={() => navigate('/admin')}>Takaisin admin-sivulle</button>
                    <button onClick={() => handleSubmit("frontDescription")}>Päivitä teksti (FIN)</button>
                    {messages.frontDescription && <p>{messages.frontDescription}</p>}
                    <button onClick={() => handleSubmit("frontDescriptionEn")}>Päivitä teksti (EN)</button>
                    {messages.frontDescriptionEn && <p>{messages.frontDescriptionEn}</p>}
                </div>
            </div>
            <div className="text-area-container">
                <h2>Aukioloajat</h2>
                <textarea 
                    value={textData.openingHours || placeholders.openingHours} 
                    onChange={(e) => handleChange(e, "openingHours")} 
                    placeholder={placeholders.openingHours}>
                </textarea>
                <textarea 
                    value={textData.openingHoursEn || placeholders.openingHoursEn} 
                    onChange={(e) => handleChange(e, "openingHoursEn")} 
                    placeholder={placeholders.openingHoursEn}>
                </textarea>
                <div className="button-container">
                    <button onClick={() => navigate('/admin')}>Takaisin admin-sivulle</button>
                    <button onClick={() => handleSubmit("openingHours")}>Päivitä teksti (FIN)</button>
                    {messages.openingHours && <p>{messages.openingHours}</p>}
                    <button onClick={() => handleSubmit("openingHoursEn")}>Päivitä teksti (EN)</button>
                    {messages.openingHoursEn && <p>{messages.openingHoursEn}</p>}
                </div>
            </div>
            <div className="text-area-container">
                <h2>Lisätiedot</h2>
                <textarea 
                    value={textData.additionalInfo || placeholders.additionalInfo} 
                    onChange={(e) => handleChange(e, "additionalInfo")} 
                    placeholder={placeholders.additionalInfo}>
                </textarea>
                <textarea 
                    value={textData.additionalInfoEn || placeholders.additionalInfoEn} 
                    onChange={(e) => handleChange(e, "additionalInfoEn")} 
                    placeholder={placeholders.additionalInfoEn}>
                </textarea>
                <div className="button-container">
                    <button onClick={() => navigate('/admin')}>Takaisin admin-sivulle</button>
                    <button onClick={() => handleSubmit("additionalInfo")}>Päivitä teksti (FIN)</button>
                    {messages.additionalInfo && <p>{messages.additionalInfo}</p>}
                    <button onClick={() => handleSubmit("additionalInfoEn")}>Päivitä teksti (EN)</button>
                    {messages.additionalInfoEn && <p>{messages.additionalInfoEn}</p>}
                </div>
            </div>
        </div>
    );
}